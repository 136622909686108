import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Title from "../../components/title"
import Button from "../../components/button"
import Input from "../../components/form/input"
import Radio from "../../components/form/radio"
import Textarea from "../../components/form/textarea"
import * as styles from "./start-project.module.scss"

export default function StartProjectPage() {
  const [submitText, setSubmitText] = React.useState(
    "Request Free Consultation Now!"
  )

  return (
    <>
      <Seo
        title="Start Project"
        description="Tell us what you and your business needs to succeed."
      />
      <Layout>
        <section className={styles.container}>
          <Title headingLevel="h1">Let’s get started</Title>
          <Title headingLevel="h2">
            Tell us what you and your business needs to succeed.
          </Title>
          <Title headingLevel="p">
            Get a <strong>free consultation</strong>
          </Title>
        </section>
        <form
          className={styles.form}
          name="Start Project"
          netlify-honeypot="bot-field"
          method="POST"
          action="/start-project/thank-you"
          data-netlify="true"
          onSubmit={e => setSubmitText("Sending...")}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Start Project" />
          <Input
            type="text"
            name="name"
            placeholder="Your name *"
            autoFocus={true}
            required={true}
          />
          <Input
            type="email"
            name="email"
            placeholder="Your business email *"
            required
          />
          <div className={styles.radioGroup}>
            <p>Select type of project:</p>
            <div>
              <Radio name="projectType" value="Website" defaultChecked />
            </div>
            <div>
              <Radio name="projectType" value="PWA" />
            </div>
            <div>
              <Radio name="projectType" value="eCommerce" />
            </div>
            <div>
              <Radio name="projectType" value="Other" />
            </div>
          </div>
          <Textarea
            placeholder="How can we help?"
            rows="4"
            name="message"
            spellCheck="false"
          ></Textarea>
          <span>
            <Button type="submit">{submitText}</Button>
          </span>
        </form>
      </Layout>
    </>
  )
}
